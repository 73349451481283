<div class="layout-header px-3 py-2 border-bottom d-flex justify-content-between align-items-center">
  <div class="header-right d-flex justify-content-center align-items-center">
    <div class="search-bar position-relative">
      <img src="assets/images/icons/hamburger-menu.svg" alt="" class="d-xl-none me-3" (click)="openNavMenu()">
      <!-- <div class="d-flex w-100 input_field p-0 overflow-hidden">
        <input type="text" class="input_field py-1 pe-3 flex-fill m-0 border-0 border-end right-tb-border remove "
          placeholder="Search Here" [(ngModel)]="search_string" (blur)="emitSearch()" (change)="emitSearch()">

        <img src="assets/images/icons/cross-icon.svg" alt="" (click)="clearSearch()" class="cursor px-2"
          *ngIf="search_string">

      </div> -->
    </div>
  </div>
  <div class="header-left d-flex justify-content-center align-items-center position-relative">
    <button class="btn btn-secondary fs-16 fw-600 me-3" [routerLink]="'/app/finance/finance-customer'" *ngIf="!showDebtBtn">Create
      Contract
    </button>
      <span [routerLink]="'/app/debt-recovery/start-recovery'" *ngIf="showDebtBtn"
      class="fs-14 fw-700 p-2 border  text-primary border-primary radius-8 bg-white d-flex justify-content-between align-items-center cursor me-3">
     + Recover Debt
  </span>
    <span class="position-relative me-3">
      <img src="assets/images/icons/bell-icon.svg" alt="" class="notify-bell rounded-circle border cursor"
        (click)="_main.showNotificationBar = true">
        <span class="position-absolute bg-danger text-white rounded-circle fs-12 hw-20 text-center" style="top: -5px; right: -5px;"  *ngIf="_main.unread_count > 0">
          {{_main.unread_count}}
        </span>
    </span>
    <img [src]="userProfile.doctor_image || 'assets/images/icons/profile_placeholder.png'" alt=""
      class="profile-icon rounded-circle border cursor hw-40" (click)="showLogoutModal()">

    <div class="logout-wrapper p-4 position-absolute bg-white end-0 radius-16" *ngIf="showLogout">
      <div class="logout-header d-flex align-items-center border-bottom pb-4 mb-4 lhc">
        <img [src]="userProfile.doctor_image || 'assets/images/icons/profile_placeholder.png'" alt=""
          class="business-img rounded-circle me-3 lhc hw-40">

        <div class="lhc">
          <h1 class="fs-16 fw-600 m-0 lhc">
            {{userProfile.doctor_name}}
          </h1>
          <p class="fs-14 fw-400 m-0 lhc">
            {{userProfile.doctor_email}}
          </p>
        </div>

      </div>

      <div class="logout-body">
        <ul class="p-0">
          <li class="d-flex align-items-center mb-3 cursor" *ngFor="let item of settingsList"
            (click)="userAction(item)">
            <img [src]="item.icon" alt="" class="rounded-circle me-3">
            <p class="fs-14 fw-600 m-0">
              {{item.label}}
            </p>
          </li>
        </ul>
      </div>

      <div class="logout-footer d-flex align-items-center pt-3 border-top mt-3">
        <img src="assets/images/icons/logout.svg" alt="" class="rounded-circle me-3 cursor" (click)="logoutModal()">
        <p class="fs-14 fw-600 m-0 cursor " (click)="logoutModal()">
          Logout
        </p>
      </div>
    </div>
  </div>
</div>