import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToasterService } from './shared/toaster/toaster.service';
import { IntercomService } from './services/intercom.service';
import { localStorageService } from './services/localstorage.service';

declare var google: any;
declare var client: any;
declare global {
  interface Window {
    gtranslateSettings: {
      // default_language: string;
      // native_language_names: boolean;
      // languages: string[];
      wrapper_selector: string;
    };
    gt_translate_script: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'business-panel';
  public env = environment;
  // subscription: Subscription;
  private routerSubscription!: Subscription;
  
  constructor(private router: Router, private intercomService: IntercomService, private _local: localStorageService) {}
  ngOnInit() {
    setTimeout(() => {
      this.initGtranslate();
    }, 2000);
    setTimeout(() => {
      this.googleInit();
    }, 2000);
    this.routerSubscription = this.router.events.subscribe(event => {
      // if (event instanceof NavigationStart) {
      //   this._toastr.hideAllToasters();
      // }
    });
    this.initIntercom();
  }

  initIntercom() {
    const userData = this._local.customGetItem('userProfileData');
    if (userData) {
      this.intercomService.initialize({
        doctor_name: userData.doctor_name,
        doctor_email: userData.doctor_email,
        doctor_id: userData.doctor_id,
      });
    }
  }

  initGtranslate() {
    const nonce = 'L2lzcfM4MzRkN0ZrVjljOA==';
    const gTranslateSettings = {
      native_language_names: true,
      languages: ['en', 'de', 'it', 'es', 'fr', 'pl', 'ja'],
      wrapper_selector: '.gtranslate_wrapper',
    };
    window.gtranslateSettings = gTranslateSettings; // Define before loading the script
  
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    script.defer = true;
    script.setAttribute('nonce', nonce); // Set nonce attribute
    
    // Set up an error handler
    script.onerror = () => {
      console.error('Error loading GTranslate widget script.');
    };
  
    // Append the script to the body
    document.body.appendChild(script);
  
    // After the script loads, trigger the next step
    script.onload = () => {
      this.loadGoogleTranslate(nonce);
    };
  }

  loadGoogleTranslate(nonce: string) {
    const element = document.querySelector('.gt-selected');
    
    if (element instanceof HTMLElement) {
      element.click(); // Trigger the click to initialize
      
      // Ensure the Google Translate script is loaded once
      if (!window.gt_translate_script) {
        window.gt_translate_script = document.createElement('script');
        window.gt_translate_script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2';
        window.gt_translate_script.defer = true;
        window.gt_translate_script.setAttribute('nonce', nonce);
        window.gt_translate_script.onerror = () => {
          console.error('Error loading Google Translate script.');
        };

        document.body.appendChild(window.gt_translate_script);
      }
    } else {
      console.log('Waiting for GTranslate element to be available...');
    }
  }

  googleInit() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://accounts.google.com/gsi/client');
    script.onload = () => {
      const client = google.accounts.id.initialize({
        client_id: this.env.google_client_id,
        use_fedcm_for_prompt: true,
        // data_use_fedcm_for_prompt: true
      });
    };
    document.body.appendChild(script);
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
