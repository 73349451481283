import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';
import { MainService } from 'src/app/services/main.service';
import { SharedService } from 'src/app/services/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { localStorageService } from 'src/app/services/localstorage.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { CaptchaService } from 'src/app/services/captcha.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReferralCodeComponent } from 'src/app/pages/public/referral-code/referral-code.component';
import { Email, Name_1 } from 'src/app/shared/regex';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {
  signUpForm: UntypedFormGroup;
  queryParamSubscription: Subscription = new Subscription;
  ip_country_id: string | undefined;
  reachOption: any = [];
  in_app_link = false;
  hidePwd = false;
  currencyList = [];
  countryList = [];
  mask = '0000-000-000';
  mobile_arr = [];
  sub_industrys = [];
  industryList = [];
  counCurData = {
    country_id: 1,
    currency_id: 1
  }
  step = {
    REGISTER: 'REGISTER',
    VERIFY_OTP: 'VERIFY_OTP'
  };
  selectedStep = '';

  otpForm: UntypedFormGroup;
  otpValueGroup = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  otp_id = '';
  referral_code = ''
  referralCodeExist = false;
  bsModalRef: BsModalRef | undefined;
  
  countdown: number = 180; // Countdown starts from 30 seconds
  countdownInterval: any;
  
  constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, public mainService: MainService,
    private metaTagService: Meta, private _captcha: CaptchaService,
    private canonicalService: CanonicalService, private _local: localStorageService,
    private apiSer: ApiService, private _shared: SharedService, private _loader: LoaderService, private modalService: BsModalService,
  ) {
    this.signUpForm = this.fb.group({
      doctor_name: ['', [Validators.required, Validators.pattern(Name_1), Validators.maxLength(30)]],
      doctor_email: ['', [Validators.required, Validators.pattern(Email)]],
      doctor_password: ['', [Validators.required, Validators.minLength(6)]],
      TnC: [false, [Validators.required]],
    });
    this.selectedStep = this.step.REGISTER;
    this.otpForm = this.fb.group({
      first: ['', [Validators.required, Validators.maxLength(1)]],
      second: ['', [Validators.required, Validators.maxLength(1)]],
      third: ['', [Validators.required, Validators.maxLength(1)]],
      fourth: ['', [Validators.required, Validators.maxLength(1)]],
      fifth: ['', [Validators.required, Validators.maxLength(1)]],
      sixth: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }

  ngOnInit(): void {
    if (environment.production) {
      this.canonicalService.setCanonicalURL('https://www.credee.com/business/register/');
      if (this.metaTagService.getTag('name= "title"')) {
        this.metaTagService.removeTag('name= "title"');
        this.metaTagService.removeTag('name= "description"');
        this.metaTagService.removeTag('name= "robots"');
        this.metaTagService.updateTag({ name: 'title', content: 'Register your Business with Credee' });
        // tslint:disable-next-line:max-line-length
        this.metaTagService.updateTag({ name: 'description', content: 'Sign up with Credee to easily manage and grow your business and take advantage of the most popular payment plan features.' });
        this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
      } else {
        this.metaTagService.addTags([
          { name: 'title', content: 'Register your Business with Credee' },
          // tslint:disable-next-line:max-line-length
          { name: 'description', content: 'Sign up with Credee to easily manage and grow your business and take advantage of the most popular payment plan features.' },
          { name: 'robots', content: 'index, follow' }
        ]);
      }
    }

    const token = this._local.customGetItem('token');

    // if access token is there then redirect to dashboard 
    if (token !== null && token !== undefined) {
      this.router.navigate(['app/dashboard']);
    }
    // this.getIndustryList();
    if (this.mainService.path_redirect_to) {
      this.router.navigate([], { queryParams: { route_link: this.mainService.path_redirect_to } });
    }
    this.mainService.ip_country_id$.subscribe(data => {
      if (data && data) {
        this.ip_country_id = data;
      }
    });
    this._shared.country_data$.subscribe(data => {
      if (data && data.length) {
        this.counCurData.country_id = data[0]['country_id'];
        this.counCurData.currency_id = data[0]['currency'][0]['currency_id'];
      }
    })

    // get details useragent to check the device
    let details = window.navigator.userAgent;

    /* Creating a regular expression
    containing some mobile devices keywords
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      console.log("You are using a Mobile Device");
      this.in_app_link = true;
    } else {
      console.log("You are using Desktop");
    }

    this.route.queryParams.subscribe(params => {
      this.referral_code = params['referral_code'] || '';
      this.referralCodeExist = true;
    });
  }

  onInput(event: any, currentIndex: number): void {
    const inputValue = event.target.value;
    console.log( typeof inputValue);
    if (!(inputValue >= 0) || !(inputValue < 10) ) {
      this.otpForm.get(this.otpValueGroup[currentIndex])?.setValue('');
      return;      
    }
    if (inputValue && (currentIndex < (this.otpValueGroup.length - 1))) {
      const nextInput = this.otpValueGroup[currentIndex + 1];
      this.setFocus(nextInput);
    }
  }

  onDelete(event: any, currentIndex: number): void {
    if (!event.target.value && currentIndex > 0) {
      const previousInput = this.otpValueGroup[currentIndex - 1];
      this.setFocus(previousInput);
    }
  }

  setFocus(input: string) {
    if (input) {
      document.getElementById(input)?.focus();
    }
  }
  // updateMasking(format: '') {
  //   this.mask = format.replace(/#/g, '0');
  //   // this.mask = '0000-000-000';
  // }

  // when business select country
  // countrySelected() {
  //   if (this.signUpForm.value && this.signUpForm.value.country_id) {
  //     const currency: Array<any> = this.countryList.filter((item: any) => item.country_id == this.signUpForm.value.country_id);
  //     this.updateMasking(currency[0]['country_phone_format']);
  //     console.log(currency[0]['country_phone_format']);
  //     if (currency && currency.length) {
  //       if (currency[0]['currency']) {
  //         this.currencyList = currency[0].currency;
  //         this.signUpForm.patchValue({ currency_id: this.currencyList[0]['currency_id'] });
  //         if (this.currencyList.length === 1) {
  //           this.signUpForm.controls['currency_id'].disable();
  //         } else {
  //           this.signUpForm.controls['currency_id'].enable();
  //         }
  //         // this.doctor_mobile_pattern = currency[0].mobile_regex;
  //         // this.phone_length = currency[0].phone_length;
  //       }
  //       const index = this.countryList.findIndex((item: any) => item.country_id == this.signUpForm.value.country_id);
  //       // this.changeFlag(index);
  //     }
  //   }
  // }

  // when business change country code
  // countryCodeSelected() {
  //   if (this.signUpForm.value && this.signUpForm.controls['doctor_mobile_country_code']) {
  //     const currency = this.countryList.filter((item: any) => item.mobile_code == this.signUpForm.controls['doctor_mobile_country_code']);
  //     if (currency && currency.length) {
  //       if (currency[0]['currency']) {
  //         this.signUpForm.patchValue({doctor_mobile: ''});
  //         // this.doctor_mobile_pattern = currency[0].mobile_regex;
  //         // this.phone_length = currency[0].phone_length;
  //       }
  //       const index = this.countryList.findIndex((item: any) => item.doctor_mobile_country_code == this.signUpForm.controls['doctor_mobile_country_code']);
  //       // this.changeFlag(index);
  //     }
  //   }
  // }

  // validate email first whether email exist or not
  async submitForm() {
    if (this.signUpForm.invalid) {
      this.signUpForm.markAsDirty();
      this.signUpForm.markAllAsTouched();
      return;
    }

    if (this.signUpForm.controls['TnC'].value == false) {
      this.signUpForm.markAsDirty();
      this.signUpForm.markAllAsTouched();
      return;
    }
    if (this.selectedStep == this.step.REGISTER) {
      if (!(this.signUpForm.controls['doctor_email'].value + '').trim()) {
        this._shared.customPopups('Please enter valid email', 1);
        return;
      }
      if (!(this.signUpForm.controls['doctor_name'].value + '').trim()) {
        this._shared.customPopups('Please enter valid doctor name', 1);
        return;
      }
      this._loader.showLoader();
      const captcha = await this._captcha.getCaptchaToken();
      // console.log('Captcha token:', token);
      const data: {
        doctor_email: string,
        captcha: string,
        referral_code?: string
      } = {
        doctor_email: this.signUpForm.controls['doctor_email'].value + '',
        captcha
      };
      if (this.referral_code) {
        data['referral_code'] = this.referral_code;
      }
      this.apiSer.api(data, 'auth/send_otp_email').subscribe(response => {
        if (response && !response.is_error) {
          this.selectedStep = this.step.VERIFY_OTP;
          this.otpForm.reset();
          this.otp_id = response.otp_id;
          // this._shared.customPopups(response.otp, 0);
          this.startCountdown();
          this.countdown = 180
        }
      })
      return;
    }

    const data = {
      doctor_name: this.signUpForm.controls['doctor_name'].value,
      doctor_email: this.signUpForm.controls['doctor_email'].value,
      doctor_password: this._shared.crypto(this.signUpForm.controls['doctor_password'].value),
      country_id: this.counCurData.country_id,
      currency_id: this.counCurData.currency_id,
      doctor_image: undefined
    };
    console.log(data);
    this.mainService.registerUser(data);
  }

  verifyOtp() {
    if (this.otpForm.invalid) {
      this.otpForm.markAsDirty();
      this.otpForm.markAllAsTouched();
      return;
    }
    let data;
    let value = '';
    this.otpValueGroup.filter(input => value += this.otpForm.value[input]);
    data = {
      otp: value,
      otp_id: this.otp_id,
      doctor_email: this.signUpForm.controls['doctor_email'].value,
    };
    this.apiSer.api(data, 'auth/verify_otp_email').subscribe((data) => {
      if (data.is_error == 0) {
        this.submitForm();
      } else {
        this.otpForm.markAsDirty();
        this.otpForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
  }
  showReferralModal() {
    const initialState = {
    };
    this.bsModalRef = this.modalService.show(ReferralCodeComponent, {
      initialState,
      class: 'modal-dialog-centered bs-modal-wrapper'
    });

    this.bsModalRef.content.onReferralCode.subscribe((referralCode: string) => {
      console.log('Referral code received:', referralCode);
      this.referral_code = referralCode;
    });
  }
  RemoveReferralCode() {
    this.referral_code = ''
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  startCountdown(): void {

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval); 
      }
    }, 1000); 
  }
  async sendOtp(){
    
    const captcha = await this._captcha.getCaptchaToken();
    let data = {
      captcha : captcha,
      doctor_email: this.signUpForm.controls['doctor_email'].value,
    }

    this.apiSer.api(data, 'auth/send_otp_email').subscribe({
      next:(response)=>{
        if (response && !response.is_error) {
          this.selectedStep = this.step.VERIFY_OTP;
          this.otp_id = response.otp_id;
          // this._shared.customPopups(response.otp, 0);
          this.startCountdown();
        }
      }
    })

   console.log(this.signUpForm,"send otp successfully");
   
  }

  renewOtp(): void {
    this.sendOtp();
    this.countdown = 180;     
  }
}

