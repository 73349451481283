<div class="login-main-wrapper">
    <div class="inner-wrapper d-flex flex-column flex-lg-row">
        <div class="">
            <app-left-layout></app-left-layout>
        </div>
        <div
            class="login-right-side flex-fill vh-100 overflow-lg-auto">
            <div class="h-100 w-100">
                <div class="header hw-65 w-100 d-flex justify-content-end align-items-center pt-4 px-4">
                    <!-- <app-google-translate [position]="'position-static'"></app-google-translate> -->
                    <div class="search-bar position-relative ms-4">
                        <img src="assets/images/icons/profile_placeholder.png" style="width: 40px;" alt="" class="profile-icon rounded-circle border cursor" (click)="showModal()">

                    </div>

                    <div class="logout-wrapper p-4 position-absolute bg-white end-0 radius-16" *ngIf="showLogout">
                        <div class="logout-header d-flex align-items-center pb-2 lhc">
                          <img [src]="'assets/images/icons/profile_placeholder.png'" style="width: 40px;"
                            alt="" class="business-img rounded-circle me-3 lhc">
                  
                          <div class="lhc">
                            <h1 class="fs-16 fw-600 m-0 lhc">
                              {{userProfile.doctor_name}}
                            </h1>
                            <p class="fs-14 fw-400 m-0 lhc">
                              {{userProfile.doctor_email}}
                            </p>
                          </div>
                  
                        </div>

                        <div class="logout-footer d-flex align-items-center pt-3 border-top mt-3">
                            <img src="assets/images/icons/logout.svg" alt="" class="rounded-circle me-3 cursor" (click)="logoutModal()">
                            <p class="fs-14 fw-600 m-0 cursor " (click)="logoutModal()">
                              Logout
                            </p>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center w-100" style="height: calc(100% - 65px);">
                    <div class="right-content p-3 p-lg-0">
                        <form action="" (submit)="submitPracticeInfo()">
                            <ng-container [formGroup]="practiceInfoForm">
                                <div class="fw-600 fs-24">
                                    Hello, <span class="fw-700">{{userProfile && userProfile.doctor_name}} </span>
                                </div>        
                                <div class="mb-4">
                                    <h2 class="fs-36 fw-600 m-0 mb-2">
                                        Enter your Business information
                                    </h2>
                                    <p class="fs-16 fw-400 text-secondary m-0">
                                        We need these information to customize your experience
                                    </p>
                                </div>
        
                                <div class=" form-group mt-3">
                                    <div class="d-flex flex-column-reverse custom-form inputContainer">
                                        <input autocomplete="off" type="text" name="email" required=""
                                            formControlName="practice_name" placeholder="Enter your business name"
                                            class="input_field ">
                                        <label for="text" class="label-name">
                                            <span class="content-name f-c-p-3 fs-14"> Business name </span>
                                        </label>
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf="((practiceInfoForm['controls']['practice_name'].touched && practiceInfoForm['controls']['practice_name'].invalid && !practiceInfoForm['controls']['practice_name'].dirty))">
                                        Please enter your Name.
                                    </p>
        
                                </div>
                                <div class=" form-group mt-3">
                                    <div class="d-flex flex-column-reverse custom-form inputContainer">
                                        <input autocomplete="off" type="text" name="email" required=""
                                            formControlName="practice_email" placeholder="Enter your  business email address"
                                            class="input_field ">
                                        <label for="text" class="label-name">
                                            <span class="content-name f-c-p-3 fs-14"> Business email </span>
                                        </label>
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 fs-14 text-danger"
                                        *ngIf=" ((practiceInfoForm['controls']['practice_email'].touched || practiceInfoForm['controls']['practice_email'].dirty) && practiceInfoForm['controls']['practice_email'].invalid)">
                                        Please enter valid email address.
                                    </p>
                                </div>
                                <div class=" form-group mt-3">
                                    <div class="d-flex flex-column-reverse custom-form inputContainer position-relative">
                                        <p class="position-absolute" style="left: 15px;top: 43px;">
                                            {{(country_data && country_data.mobile_code) ? country_data.mobile_code : '+1'}}</p>
                                        <input style="padding-left: 45px;" [mask]="mask" autocomplete="off" type="text"
                                            name="email" required="" formControlName="practice_mobile"
                                            placeholder="Enter phone number" class="input_field">
                                        <label for="text" class="label-name">
                                            <span class="content-name f-c-p-3 fs-14">Business phone number </span>
                                        </label>
                                    </div>
        
                                    <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                        *ngIf=" ((practiceInfoForm['controls']['practice_mobile'].touched || practiceInfoForm['controls']['practice_mobile'].dirty) && practiceInfoForm['controls']['practice_mobile'].invalid)">
                                        Please enter a valid Phone number </p>
                                </div>
                                <div class=" form-group mt-3">
                                    <label for="text" class="label-name">
                                        <span class="content-name f-c-p-3 fs-14">Industry </span>
                                    </label>
                                    <select name="" id="" class="input_field w-100" placeholder="Industry"
                                        formControlName="ind_id" (change)="getSubIndustrylist();">
                                        <option [value]="''" disabled>Select industry</option>
                                        <option *ngFor="let industry of industryList" [value]="industry.ind_id">{{
                                            industry['ind_name'] }}</option>
                                    </select>
                                    <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                    *ngIf=" ((practiceInfoForm['controls']['ind_id'].touched || practiceInfoForm['controls']['ind_id'].dirty) && practiceInfoForm['controls']['ind_id'].invalid)">
                                    Industry is required </p>
                                </div>
                                <div class=" form-group mt-3">
                                    <label for="text" class="label-name">
                                        <span class="content-name f-c-p-3 fs-14">Sub Industry </span>
                                    </label>
                                    <select name="" id="" class="input_field w-100" placeholder="Sub Industry"
                                        formControlName="doctor_speciality">
                                        <option *ngFor="let subindustry of sub_industries"
                                            [value]="subindustry.doctor_speciality">{{
                                            subindustry['speciality_name'] }}</option>
                                        <option *ngIf="!sub_industries.length" value="">Select Sub Industry</option>
                                    </select>
                                    <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                    *ngIf=" ((practiceInfoForm['controls']['doctor_speciality'].touched || practiceInfoForm['controls']['doctor_speciality'].dirty) && practiceInfoForm['controls']['doctor_speciality'].invalid)">
                                    Sub Industry is required </p>
                                </div>
        
                                <div class="form-group mt-3" *ngIf="practiceInfoForm.controls['ind_id'].value == 9999">
                                    <div class="d-flex flex-column-reverse custom-form inputContainer">
                                        <input type="text" class="input_field pt-3 px-3" required name="ind_name"
                                            autocomplete="none" placeholder="" trim="true" formControlName="ind_name" />
                                        <label for="text" class="label-name">
                                            <span class="content-name f-c-p-3 fs-14">Industry Name </span>
                                        </label>
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 text-danger"
                                        *ngIf="practiceInfoForm.controls['ind_name'] && (practiceInfoForm.controls['ind_name'].touched && practiceInfoForm.controls['ind_name'].invalid) && !practiceInfoForm.controls['ind_name'].dirty ">
                                        Please enter industry name.
                                    </p>
                                </div>
        
                                <div class="form-group col-md-12 py-2"
                                    *ngIf="practiceInfoForm.controls['doctor_speciality'].value == 9999 ">
                                    <div class="d-flex flex-column custom-form inputContainer">
                                        <label for="text" class="label-name">
                                            <span class="content-name f-c-p-3">
                                                Sub Industry Name
                                            </span>
                                        </label>
                                        <input type="text" class="input_field pt-3 px-3" required name="doctor_speciality_name"
                                            autocomplete="none" placeholder="" trim="true"
                                            formControlName="doctor_speciality_name" />
                                    </div>
                                    <p class="mat-error mb-0 ml-0 pl-0 text-danger"
                                        *ngIf="practiceInfoForm.controls['doctor_speciality_name'] && (practiceInfoForm.controls['doctor_speciality_name'].touched && practiceInfoForm.controls['doctor_speciality_name'].invalid && !practiceInfoForm.controls['doctor_speciality_name'].dirty)">
                                        Please
                                        enter sub industry name.
                                    </p>
                                </div>
                            </ng-container>
                            <button class="w-100 btn btn-secondary fw-600 mt-3" type="submit">
                                Continue
                            </button>
        
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>