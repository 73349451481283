import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrl: './sidemenu.component.scss',
})
export class SidemenuComponent {
  menuList: any = [];
  currentUrl: string = '';  // Store the current route
  activeMenuStates: { [path: string]: boolean } = {};  // Store active states for menu items

  constructor(private _router: Router, private _main: MainService, private _shared: SharedService) {}

  ngOnInit() {
    this._main._shared.authenticateApiResponse$.subscribe((allLoginDataResponse: any) => {
      if (allLoginDataResponse.menu && allLoginDataResponse.menu.length) {
        this.menuList = [];
        // fetch all parent menus with only path 0 . // 0 here means to display menu and 1 means do not display but is a route
        const parentMenus = allLoginDataResponse.menu.filter((menu: any) => !menu.parent_id && menu.only_path == 0);
        parentMenus.map((item: any) => {
          if (!item.show_to_business) {
            return;
          }
          if (item.submenu && item.submenu.length) {
            const submenus = item.submenu.filter((sub_menu: any) => sub_menu.only_path == 0);
            const menuItem = {...item};
            menuItem.submenu = submenus;
            this.menuList.push(menuItem);
          } else {
            this.menuList.push(item);
          }
        })
        
        this.currentUrl = this._router.url;
        this.updateMenuActiveStates();
        console.log(this.menuList);
        this._main._shared.sideMenus$.next(this.menuList);
      }
    });

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentUrl = this._router.url;
      this.updateMenuActiveStates();
    });
  }

  private updateMenuActiveStates(): void {
    // Reset all menu states
    this.activeMenuStates = {};

    // Compute active state for each menu
    this.menuList.forEach((menu: any) => {
      // Check if the current URL starts with the menu path
      this.activeMenuStates[menu.path] = this.currentUrl.startsWith(menu.path);

      // If there are submenus, compute their active state
      if (menu.submenu) {
        menu.submenu.forEach((sub_menu: any) => {
          this.activeMenuStates[sub_menu.path] = this.currentUrl.startsWith(sub_menu.path);
        });
      }
    });
  }

  toggleMenu(selectedMenu: any) {
    if (selectedMenu.submenu && selectedMenu.submenu.length) {
      selectedMenu.isOpen = !selectedMenu.isOpen;
    } else {
      this._router.navigate([selectedMenu.path]);
    }
    // if (menu === selectedMenu) {
    // } else {
    //   // menu.isOpen = false;
    // }
    // this.menuList.forEach((menu: any) => {
    // });
  }

  routeClick() {
    // this._shared.search_emitter.subscribe(data => {
    //   if(data && data.length) {
    //     this._shared.search_emitter.next('');
    //     window.location.reload();
    //   }
    // })
  }


  closeNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(-100%)';
    }
  }
}
