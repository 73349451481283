import { Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { LayoutComponent } from '../layouts/layout.component';
import { authGuard } from '../auth.gaurd';
import { RegisterComponent } from './public/register/register.component';
import { NewPasswordComponent } from './public/new-password/new-password.component';
import { VerifyPhoneNumberComponent } from './public/verify-phone-number/verify-phone-number.component';
import { BusinessInfoComponent } from './public/business-info/business-info.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { NotFoundComponent } from './public/not-found/not-found.component';

export const routes: Routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    {
        path: 'app',
        component: LayoutComponent,
        canActivate: [authGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
                // canActivate: [FreezGuard],

            },
            {
                path: 'contracts',
                loadChildren: () => import('./components/contract/contract.module').then(m => m.ContractModule),
                // canActivate: [FreezGuard],
            },
            {
                path: 'proposals',
                loadChildren: () => import('./components/proposal/proposal.module').then(m => m.ProposalModule),
                // canActivate: [FreezGuard],
            },
            {
                path: 'finance',
                loadChildren: () => import('./components/finance/finance.module').then(m => m.FinanceModule),
                data: { preload: true }
                // canActivate: [FreezGuard],
            },
            {
                path: 'earnings',
                loadChildren: () => import('./components/earnings/earnings.module').then(m => m.EarningsModule),
                // canActivate: [FreezGuard],

            },
            {
                path: 'profile',
                loadChildren: () => import('./components/profile-layout/profile.module').then(m => m.ProfileModule),
            },

            {
                path: 'support',
                loadChildren: () => import('./components/support/support.module').then(m => m.SupportModule),
            },
            {
                path: 'financial-settings',
                loadChildren: () => import('./components/financial-settings/financial-settings.module').then(m => m.FinancialSettingModule),
            },

            {
                path: 'manage-users',
                loadChildren: () => import('./components/manage-users/manage-users.module').then(m => m.ManageUsersModule),
            },
            {
                path: 'notifications',
                loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule),
            },
            {
                path: 'debt-recovery',
                loadChildren: () => import('./components/debt-recuvery/debt-recuvery.module').then(m => m.DebtRecuveryModule),
            },
            { path: '404', component: NotFoundComponent },
            { path: '**', component: NotFoundComponent },
        ]
    },
    { path: 'new-password', component: NewPasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'login', component: LoginComponent },
    { path: 'verify-phone-number', canActivate: [authGuard], component: VerifyPhoneNumberComponent },
    { path: 'business-info', canActivate: [authGuard], component: BusinessInfoComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: '**', redirectTo: 'login' }
]
