<form [formGroup]="dateForm" *ngIf="dateOfBirthMask && dateOfBirthMask.fieldOrder" class="position-relative">
  <span class="position-absolute top-0 start-0 pt-1 ps-3 fs-10 text-muted ZI-2" *ngIf="fromPage == 'contractCreation'">Date of Birth</span>
  <div class="d-flex align-items-center input_field p-0 position-relative">
  <ng-container *ngFor="let field of dateOfBirthMask.fieldOrder; let i = index">
      <!-- <label>{{ field.key | titlecase }}:</label> -->
      <div class="d-flex align-items-center hw-56 " [ngStyle]="{'width': (i != (dateOfBirthMask.fieldOrder.length - 1)) ? '70px' : '','min-width': (i != (dateOfBirthMask.fieldOrder.length - 1)) ? '70px' : '' }" [ngClass]="{'flex-fill': i === (dateOfBirthMask.fieldOrder.length - 1), 'h-100': fromPage == 'personalInfo'}" >
        <input #inputElement type="number" class="input_field m-0 border-0 w-100" [id]="'dob_' + i"  (input)="onDateInput($event, i)" [formControlName]="field.key" [placeholder]="field.placeholder" autocomplete="off"  />
        <span *ngIf="i != (dateOfBirthMask.fieldOrder.length - 1)">
          {{dateOfBirthMask.specialCharacters[0]}}
        </span>
      </div>
    </ng-container>
  </div>
</form>
<p *ngIf="error" class="mat-error text-danger w-100 d-block mb-0 fs-12"> Please enter valid date of birth </p>