import { ModuleWithProviders, NgModule } from "@angular/core";
import { LoaderComponent } from "./loader/loader.component";
import { CommonModule } from "@angular/common";
import { ApiService } from "../services/api.service";
import { ToasterService } from "./toaster/toaster.service";
import { ToasterContainerComponent } from "./toaster/toaster-container-component";
import { ToasterComponent } from "./toaster/toaster.component";
import { LogoutModalComponent } from "./logout-modal/logout-modal.component";
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleAuthService } from "../services/google-auth.service";
import { localStorageService } from "../services/localstorage.service";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CalendarComponent } from "./calendar/calendar.component";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleZipComponent } from "./google-zip/google-zip.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GenerateStripeElementComponent } from "./generate-stripe-element/generate-stripe-element.component";
import { ClipboardModule } from "ngx-clipboard";
import { SignaturePadComponent } from "./signature-pad/signature-pad.component";
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SocketsService } from "../services/socket.service";
import { DateOfBirthComponent } from "../pages/public/date-of-birth/date-of-birth.component";
import { NoSpacesDirective } from "./no-space.directive";
import { LeftLayoutComponent } from "../pages/public/left-layout/left-layout.component";
import { NewPasswordComponent } from "../pages/public/new-password/new-password.component";

@NgModule({
    declarations: [LoaderComponent, ToasterComponent, ToasterContainerComponent, LogoutModalComponent, CalendarComponent, GoogleZipComponent, GenerateStripeElementComponent, SignaturePadComponent, DateOfBirthComponent, NoSpacesDirective, NewPasswordComponent, LeftLayoutComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        NgxMaskDirective,
        NgxGpAutocompleteModule,
        NgxMaskPipe,
        ClipboardModule,
        PaginationModule.forRoot()
    ],
    providers: [localStorageService, ApiService, ToasterService, GoogleAuthService,SocketsService, 
      {
        provide: Loader,
        useValue: new Loader({
          apiKey: window.location.href.includes('.financemutual.com') ? 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE' :'AIzaSyAB3iHn_Dc0nEjS7q53tfCBWVm1_Dj1fdI',
          libraries: ['places']
        })
      },provideNgxMask()],
    exports: [LoaderComponent, ToasterContainerComponent, BsDropdownModule, NgxMaskDirective, NgxMaskPipe, NgxGpAutocompleteModule, CalendarComponent, GoogleZipComponent, ClipboardModule, SignaturePadComponent, PaginationModule, TooltipModule, DateOfBirthComponent, NoSpacesDirective,NewPasswordComponent, LeftLayoutComponent,]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
      return {
        ngModule: SharedModule
      };
    }
  }